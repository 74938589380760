import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { variants } from './typography.lib';
import styles from '././typography.module.scss';

type TypographyProps = PropsWithChildren &
  HTMLAttributes<HTMLSpanElement> & {
    as?:
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'p'
      | 'span'
      | 'label'
      | 'time';
    variant:
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'h7'
      | 'h8'
      | 'h9'
      | 'subtitle1'
      | 'subtitle2'
      | 'subtitle3'
      | 'subtitle4'
      | 'subtitle5'
      | 'subtitle5_1'
      | 'subtitle5_2'
      | 'subtitle6'
      | 'body1'
      | 'body1_1'
      | 'body2'
      | 'body3'
      | 'numberAdmin1'
      | 'numberAdmin2'
      | 'numberAdmin3'
      | 'numbers1'
      | 'numbers2'
      | 'small1'
      | 'small2'
      | 'small2_3'
      | 'small3';
    nowrap?: boolean;
    center?: boolean;
    wordBreak?: boolean;

    className?: string;

    //label-props
    htmlFor?: string;

    asChild?: boolean;
  };

const Typography = createCompoundComponent(
  () =>
    //TODO: fix forwardRef `as` typings
    forwardRef<HTMLSpanElement, TypographyProps>(
      (
        {
          as = 'span',
          variant,
          nowrap,
          center,
          className,
          asChild,
          wordBreak,
          ...props
        },
        ref
      ) => {
        const Component = asChild ? Slot : (as as 'span');

        return (
          <Component
            ref={ref}
            className={clsx(styles.typography, styles[variant], className)}
            data-center={!!center}
            data-nowrap={!!nowrap}
            data-word-break={!!wordBreak}
            {...props}
          />
        );
      }
    ),
  {
    variants,

    classes: {
      h1: styles.h1,
      h2: styles.h2,
      h3: styles.h3,
      h4: styles.h4,
      h5: styles.h5,
      h6: styles.h6,
      h7: styles.h7,
      h8: styles.h8,
      h9: styles.h9,
      subtitle1: styles.subtitle1,
      subtitle2: styles.subtitle2,
      subtitle3: styles.subtitle3,
      subtitle4: styles.subtitle4,
      subtitle5: styles.subtitle5,
      subtitle5_1: styles.subtitle5_1,
      subtitle5_2: styles.subtitle5_2,
      subtitle6: styles.subtitle6,
      body1: styles.body1,
      body1_1: styles.body1_1,
      body2: styles.body2,
      body3: styles.body3,
      numberAdmin1: styles.numberAdmin1,
      numberAdmin2: styles.numberAdmin2,
      numberAdmin3: styles.numberAdmin3,
      numbers1: styles.numbers1,
      numbers2: styles.numbers2,
      small1: styles.small1,
      small2: styles.small2,
      small2_3: styles.small2_3,
      small3: styles.small3,
    },
  }
);

export { Typography };
export type { TypographyProps };
