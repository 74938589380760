import { TransferType as PublicTransferType } from '@kuna-pay/core/generated/public/graphql';

const transactionTypeToI18n: Record<PublicTransferType, string> = {
  [PublicTransferType.InvoiceDeposit]:
    'entities.transaction.ui.type.invoice-deposit',
  [PublicTransferType.Deposit]: 'entities.transaction.ui.type.deposit',
  [PublicTransferType.Withdraw]: 'entities.transaction.ui.type.withdraw',
  [PublicTransferType.PayoutWithdraw]: 'entities.transaction.ui.type.payout',
  [PublicTransferType.Convert]: 'entities.transaction.ui.type.convert',
  [PublicTransferType.ManualConvert]: 'entities.transaction.ui.type.convert',
  [PublicTransferType.ReferralReward]: 'entities.transaction.ui.type.referral',

  // no-op
  [PublicTransferType.Refund]: '',
};

export { transactionTypeToI18n };
