import type { FC, PropsWithChildren, SVGProps } from 'react';
import type { ToastContentProps } from 'react-toastify';

import {
  CloseIcon,
  InfoIcon,
  Success24Icon,
  WarningIcon,
} from '@kuna-pay/ui/icons';
import { IconButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { ToastType } from '../notification.types';
import styles from './toast.module.scss';

type ToastProps = Partial<ToastContentProps> & {
  type: ToastType;
};

const Toast: FC<PropsWithChildren<ToastProps>> = ({
  type,
  closeToast,
  children,
}) => {
  const Icon = Icons[type] ?? Icons.success;

  return (
    <div className={styles.root}>
      <div className={styles.iconContainer} data-variant={type}>
        <Icon width={24} height={24} />
      </div>

      <Typography className={styles.message} variant='numbers1'>
        {children}
      </Typography>

      <IconButton className={styles.close} onClick={closeToast}>
        <CloseIcon width={24} height={24} />
      </IconButton>
    </div>
  );
};

const Icons: Record<ToastType, FC<SVGProps<SVGSVGElement>>> = {
  warning: WarningIcon,
  error: WarningIcon,
  success: Success24Icon,
  info: InfoIcon,
};

export { Toast };
